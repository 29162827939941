.signature-container {
  display: flex;

  .label {
    min-width: 175px;
    font-size: 16px;
    line-height: 19px;
  }

  .sign-wrap {
    canvas {
      border: 1px solid #DDDDDD;
      box-sizing: border-box;
      border-radius: 8px;
    }

    .btn-sign-wrap {
      display: flex;
      font-size: 16px;
      line-height: 20px;

      .clear {
        margin-right: 16px;
        color: #165FBB;
        cursor: pointer;
      }

      .upload-button {
        cursor: pointer;
      }
    }
  }

  .sign-img-wrap {
    display: flex;
    align-items: flex-start;
    
    span {
      color: red;
    }
  }
}