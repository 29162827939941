@import '../../../../assets/styles/color.scss';

.card-detail-container.container.schedule-form-confirm {
  main {
    padding: 0;
  }

  .confirm-container {
    padding: 24px 70px 24px 40px;

    .title {
      margin-bottom: 24px;
    }

    .table-email-container {
      .row-table {
        display: flex;
        justify-content:space-between;
        align-items: center;
        color: #000;
        padding: 32px 0 0;
        font-size: 16px;

        &.head {
          font-weight: 500;
          padding-bottom: 13px;
          border-bottom: 1px solid #ddd;
        }

        i {
          font-size: 14px;
          color: #666;
        }

        .input-container .input-wrap {
          margin-bottom: 0;
        }

        .col1 {
          width: calc(248px + 37px + 28px);
        }

        .col2 {
          width: 154px;
        }

        .col3 {
          width: 115px;
        }

        .col4 {
          width: 30px;
        }

        .col5 {
          width: 243px;
        }
      }
    }

    .agreement-container {
      margin-top: 50px;

      .check-list {
        margin-bottom: 16px;
      }

      .red-info {
        font-size: 16px;
        line-height: 19px;
        color: #FD5457;
      }

      .blue-text {
        color: $link;
        padding-left: 5px;
        display: inline-block;
        cursor: pointer;
      }
    }
  }
  
  .btn-wrap {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    border-top: solid 1px $grey-border;
    padding: 24px 70px 24px 40px;
  }
}

.modal-dialog.success {
  max-width: 526px;

  .modal-body {
    padding: 0 55px;
  }

  .modal-footer {
    margin-top: 16px;

    .the-btn {
      width: max-content;
    }
  }

  .top-wrap {
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .icon {
      margin-bottom: 27px;
    }
  }

  .desc-wrap {
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #111111;

    .type {
      margin-top: 16px;
      font-weight: 500;
      color: $blue-darker;
    }
  }
}