$blue-darker: #053960;
$grey: #F7F7F7;
$white: #FFFFFF;

// Homepage menu
$soft-black: #111111;
$light-grey: rgba(0, 0, 0, 0.38);

$grey-border: #DDDDDD;
$dark-grey: #666666;
$link: #165FBB;

// Button
$green: #7EAD26; 
$warning-color: #FEBE23;
$light-green: #30D569;
$light-blue: #165FBB;