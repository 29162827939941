@import './color.scss';

%input-styling {
  width: 100%;
  border: 1px solid $grey-border;
  border-radius: 8px;
  font-size: 16px;
  line-height: 16px;
  height: 53px;
  padding: 18px 16px;

  /* placeholder */
  &::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}