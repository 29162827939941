@import '../../../../assets/styles/color.scss';

.card-detail-container.registration-review {
  main {
    padding: 0;

    .review-wrap {
      .number-item {
        padding: 24px 70px 24px 32px;
        display: flex;

        &:not(:first-child) {
          border-top: 1px solid $grey-border;
        }

        .item:not(:first-child) {
          margin-left: 50px;
        }

        .label {
          font-size: 18px;
          line-height: 24px;
          color: $dark-grey;
          margin-bottom: 8px;
        }
        
        .text {
          font-weight: 500;
          font-size: 32px;
          line-height: 37px;
          color: $soft-black;
          max-width: 400px;
        }
        
      }
    }

    .btn-wrap {
      padding: 24px 40px;
      border-top: 1px solid $grey-border;
      display: flex;
      justify-content: flex-end;

      .the-btn:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
}

.modal-dialog.success.registration .modal-footer .the-btn {
  width: max-content;
}