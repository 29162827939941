@import '../../../../assets/styles/color.scss';

.schedule-form {
  .top {
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 40px;

    .open-info {
      width: 214px;
      height: 36px;
      background: #f2f7ea;
      border-radius: 6px;
      color: #7EAD26;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
    }

    .slot-wrap {
      text-align: center;

      .title {
        margin-bottom: 8px;
        font-size: 16px;
        line-height: 19px;
        color: #111111;
      }

      .slot-info {
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        color: #FD5457;
      }      
    }

    .ny-time {
      font-size: 12px;
      line-height: 14px;
      color: #666666;
      position: absolute;
      right: 0;
    }
  }

  .time-container {
    width: calc(550px + 8px + 8px);
    margin: 32px auto 0;

    .instruction {
      text-align: center;
      margin-bottom: 16px;
      font-size: 16px;
      line-height: 19px;
      color: #666666;
    }

    .prev {
      cursor: pointer;

      &.disable {
        cursor: not-allowed;
        opacity: 0.5;
      }

      .icon {
        margin-right: 8px;
      }
    }

    .next {
      cursor: pointer;
      
      .icon {
        margin-left: 8px;
      }
    }
    
    .time-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $blue-darker;
      font-weight: 500;

      .calender-wrap {
        border: 1px solid #F7F7F7;
        padding: 5px 8px;
        display: flex;
        align-items: center;
        margin: 0px 8px;

        .icon {
          margin-right: 8px;
        }
      }
    }
  }

  .schedule-wrap {
    margin-top: 32px;
    display: flex;

    .calendar-container {
      width: calc(100% / 7);
      min-width: 121px;

      &.disable {
        cursor: not-allowed;
        opacity: 0.5;
      }

      .time-wrap {

        &.error {
          .time:not(.selected):not(.disable) {
            border: 1px solid red;
          }
        }
      }

      .day-date {
        text-align: center;
        border-bottom: 1px solid #ddd;
        margin-bottom: 16px;

        .day {
          font-weight: 500;
          font-size: 20px;
          line-height: 23px;
          color: #111111;
          margin-bottom: 5px;
        }

        .date {
          font-size: 12px;
          line-height: 14px;
          color: #666666;
          margin-bottom: 16px;
        }
      }

      .time {
        width: 121.34px;
        height: 62px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #F7F7F7;
        font-weight: 500;
        margin: 0 auto 8px;
        cursor: pointer;

        &.selected {
          background: $blue-darker;
          color: white;
        }

        &.disable {
          cursor: not-allowed;
          color: #ccc;
          pointer-events: none;
        }
      }
    }

  }

  .btn-wrap {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }

  .error-message {
    color: red;
    font-size: 14px;
  }
}