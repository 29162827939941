.review-courses {
  width: 874px;
  margin: 0 auto;
  background: white;

  .container {
    padding: 30px;
    border-bottom: 1px solid #ddd;

    .back {
      font-size: 12px;
      line-height: 14px;
      color: #053960;
      margin-bottom: 24px;
      display: flex;
      align-items: center;

      .icon {
        margin-right: 14px;
      }
    }

    .success-message {
      font-size: 14px;
      line-height: 16px;
      color: #111111;
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      .success {
        display: flex;
        align-items: center;
      }

      .number {
        font-size: 14px;
        color: #666666;
        margin-left: 24px;
      }

      .green-check {
        width: 24px;
        height: 24px;
        margin-right: 24px;
      }
    }

    .detail {
      margin-bottom: 16px;

      .label {
        font-size: 12px;
        line-height: 14px;
        color: #666666;
        margin-bottom: 8px;
      }

      .val {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #053960;
      }
    }
  }

  .btn-wrap {
    padding: 30px;
  }
}